function isYMAvailable() {
    return process.env.NODE_ENV !== "development" && typeof ym === "function";
}

function vkPixelReachGoal(goal, value) {
    const data = { type: "reachGoal", id: window.VK_PIXEL_ID, goal: goal };
    if (value !== "undefined" && value !== null) {
        data.value = value;
    }
    if (process.env.NODE_ENV !== "development" && typeof window._tmr !== "undefined") {
        _tmr.push(data);
    } else {
        console.log("_tmr", JSON.stringify(data, null, 2));
    }
}

function gtmSendEvent(data) {
    if (process.env.NODE_ENV !== "development" && typeof window.dataLayer !== "undefined") {
        window.dataLayer.push(data);
    } else {
        // console.log(data)
        console.log("dataLayer", JSON.stringify(data, null, 2));
    }
}

function gtmSendEcommerceEvent(actionType, products, actionField) {
    const data = {
        ecommerce: {
            currencyCode: "RUB",
            [actionType]: {
                products: products || [],
            },
        },
    };
    if (actionField) {
        data.ecommerce[actionType].actionField = actionField;
    }
    gtmSendEvent(data);
}

function gtmBuildProducts(gameType, price) {
    return [{ name: gameType ? gameType : "Игра не указана", price: price ? price : 0 }];
}

export function gtmOnOpenEnrollmentModal(gameType, price) {
    gtmSendEcommerceEvent("detail", gtmBuildProducts(gameType, price));
    vkPixelReachGoal("open-enrollment-modal");
}

export function gtmOnEnrollment(responseData) {
    const { participant_id, game_type, amount } = responseData;
    gtmSendEcommerceEvent("add", gtmBuildProducts(game_type, amount));
    if (isYMAvailable()) {
        ym(window.METRIKA_ID, "reachGoal", "enrollment");
    }
    vkPixelReachGoal("enrollment", amount);
    if (participant_id && !amount) {
        // Покупки по абонементу проходят, как оплата за игру
        gtmOnPaymentForEnrollment(participant_id, game_type, amount);
        vkPixelReachGoal("payment", amount);
    }
}

export function gtmOnSocialEnrollment(socialNetwork, gameType, price) {
    gtmSendEcommerceEvent("add", gtmBuildProducts(gameType, price));
    vkPixelReachGoal("enrollment", price);
}

export function gtmOnPaymentForEnrollment(participantId, gameType, price) {
    const key = `purchased-${participantId}`;
    if (localStorage.getItem(key)) {
        return;
    }
    gtmSendEcommerceEvent("purchase", gtmBuildProducts(gameType, price), {
        id: `PART#${participantId}`,
    });
    vkPixelReachGoal("payment", price);
    localStorage.setItem(key, "true");
}

export function gtmOnCancelEnrollment(gameType, price) {
    gtmSendEcommerceEvent("remove", gtmBuildProducts(gameType, price));
    vkPixelReachGoal("cancel-enrollment", price);
}

export function gtmOnSubscription(responseData) {
    const { tariff_name, amount } = responseData;
    gtmSendEcommerceEvent("add", gtmBuildProducts(tariff_name, amount));
    if (isYMAvailable()) {
        ym(window.METRIKA_ID, "reachGoal", "subscription");
    }
    vkPixelReachGoal("subscription", amount);
}

export function gtmOnPaymentForSubscription(subscriptionId, tariffName, price) {
    const key = `purchased-subscription-${subscriptionId}`;
    if (localStorage.getItem(key)) {
        return;
    }
    gtmSendEcommerceEvent("purchase", gtmBuildProducts(tariffName, price), {
        id: `SUBS#${subscriptionId}`,
    });
    vkPixelReachGoal("payment-subscription", price);
    localStorage.setItem(key, "true");
}

export function onFranchiseeRequest() {
    if (isYMAvailable()) {
        ym(window.METRIKA_ID, "reachGoal", "franchisee-request");
    }
    vkPixelReachGoal("franchisee-request");
}
