export default function Picture({ baseUrl, width, height, alt, className, isPng }) {
    let imageSrc, imageType, regularSrcSet;
    if (isPng) {
        imageSrc = `${baseUrl}-1x.png`;
        imageType = "png";
        regularSrcSet = `${baseUrl}-1x.png 1x, ${baseUrl}-2x.png 2x`;
    } else {
        imageSrc = `${baseUrl}-1x.jpg`;
        imageType = "jpeg";
        regularSrcSet = `${baseUrl}-1x.jpg 1x, ${baseUrl}-2x.jpg 2x`;
    }

    return (
        <picture>
            <source type="image/webp" srcSet={`${baseUrl}-1x.webp 1x, ${baseUrl}-2x.webp 2x`} />
            <source type={`image/${imageType}`} srcSet={regularSrcSet} />
            <img src={imageSrc} srcSet={regularSrcSet} width={width} height={height} alt={alt} className={className} />
        </picture>
    );
}
