import Link from "next/link";

export default function PrivacyNote({ className }) {
    return (
        <p className={className || "privacy-note"}>
            Нажимая на кнопку, вы принимаете{" "}
            <Link href="/user-agreement/">
                <a target="_blank">пользовательское соглашение</a>
            </Link>{" "}
            и даете согласие на обработку персональных данных, а также соглашаетесь c{" "}
            <Link href="/privacy/">
                <a target="_blank">политикой конфиденциальности</a>
            </Link>
        </p>
    );
}
