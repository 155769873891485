const DEFAULT_NATIONAL_NUMBER_LENGTH = 10;
const MIN_INTL_NUMBER_LENGTH = 10;
const DEFAULT_PHONE_MASK = "(999) 999-99-99";
const ALL_DIGITS_MASK = "999999999999";

// Sync this code with `core/phonecountries.py` in case of edit
const ALL_COUNTRIES = [
    { code: "+7", name: "Россия", alt_code: "8" },
    { code: "+375", name: "Беларусь", mask: "(99) 999-99-99", nn_len: 9 },
    { code: "+380", name: "Украина", mask: "(99) 999-99-99", nn_len: 9 },
    { code: "+90", name: "Турция" },
    { code: "+86", name: "Китай", mask: "999-9999-9999", nn_len: 11 },
    // nn_len -1 - no trim/check
    { code: "+1", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+2", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+3", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+4", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+5", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+6", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+8", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+9", name: "", mask: ALL_DIGITS_MASK, nn_len: -1 },
    { code: "+7777", name: "", mask: "999-99-99" },
];

const COUNTRIES_BY_PHONE_CODE = {};

ALL_COUNTRIES.forEach((country) => {
    COUNTRIES_BY_PHONE_CODE[country.code] = country;
});

const VALID_PHONE_CODES = (process.env.NEXT_PUBLIC_INTL_PHONE_CODES || "+7").split(",");

const VALID_PHONE_CODES_BY_SIZE = [...VALID_PHONE_CODES].sort((a, b) => {
    if (a.length != b.length) {
        return b.length - a.length;
    }
    return VALID_PHONE_CODES.indexOf(a) - VALID_PHONE_CODES.indexOf(b);
});

const VALID_PHONE_COUNTRIES = [];

VALID_PHONE_CODES.forEach((code) => {
    const country = COUNTRIES_BY_PHONE_CODE[code];
    if (country) {
        VALID_PHONE_COUNTRIES.push(country);
    }
});

export {
    VALID_PHONE_CODES,
    VALID_PHONE_CODES_BY_SIZE,
    VALID_PHONE_COUNTRIES,
    COUNTRIES_BY_PHONE_CODE,
    DEFAULT_PHONE_MASK,
    DEFAULT_NATIONAL_NUMBER_LENGTH,
    MIN_INTL_NUMBER_LENGTH,
    ALL_DIGITS_MASK,
};
