import { useEffect, useState } from "react";

export function useCountdown(targetDate) {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((countDownDate - new Date().getTime()));
        }, 1000);
        return () => clearInterval(interval);
    }, [countDownDate]);

    const countDownSeconds = countDown / 1000;
    const hours = Math.floor(countDownSeconds / 3600);
    const minutes = Math.floor(countDownSeconds / 60) % 60
    const seconds = Math.floor(countDownSeconds % 60);

    return [hours, minutes, seconds];
}

export function formatTime(hours, minutes, seconds) {
    if (hours + minutes + seconds <= 0) {
        return "0:00";
    }

    const paddedSeconds = ("0" + seconds).slice(-2);
    if (hours > 0) {
        const paddedMinutes = ("0" + minutes).slice(-2);
        return `${hours}:${paddedMinutes}:${paddedSeconds}`;
    }

    return `${minutes}:${paddedSeconds}`;
}

export default function Countdown({ targetDate, onExpire }) {
    const [hours, minutes, seconds] = useCountdown(targetDate);

    if (hours + minutes + seconds <= 0) {
        onExpire && onExpire();
    }

    return <>{formatTime(hours, minutes, seconds)}</>;
}
