import Link from "next/link";

// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";

import ThumbnailPicture from "./thumbnailpicture";

export default function Avatar({ player, size, className, lazy, noLink }) {
    const { avatar, thumbnail, url } = player;
    const title = player.name + (player.mafia_nick && player.mafia_nick.length > 0 ? ` / ${player.mafia_nick}` : "");
    const image = avatar ? (
        <ThumbnailPicture
            thumbnail={thumbnail}
            width={size}
            height={size}
            alt={title}
            className={noLink ? className : null}
            lazy={lazy}
        />
    ) : (
        <img
            src="/img/avatar-place-holder.png"
            alt={title}
            width={size}
            height={size}
            className={noLink ? className : null}
        />
    );
    if (noLink) {
        return image;
    }

    const avatarTag = (
        <Link href={url || ""}>
            <a title={title} className={className} tabIndex="-1">
                {image}
            </a>
        </Link>
    );
    return avatarTag;
}
