import Link from "next/link";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export default function SharesInfo({ sharesInfo, className, isDark }) {
    if (!sharesInfo || !sharesInfo.share_price || !sharesInfo.dividends) {
        return null;
    }

    const popupClassName = "header__account-summary" + (isDark ? " header__account-summary--dark" : "");
    const priceInfoPopover = (
        <Popover id="popover-header-account-summary" className={popupClassName}>
            <Popover.Body>
                На {sharesInfo.date}:
                <div className="my-2">
                    Стоимость акций OK4U.club:
                    <br />
                    <span className="text-nowrap">
                        <b>{sharesInfo.share_price}</b> руб.
                    </span>
                </div>
                {sharesInfo.dividends_month && (
                    <>
                        Размер дивидендов за {sharesInfo.dividends_month}:
                        <br />
                        <span className="text-nowrap">
                            <b>{sharesInfo.dividends}</b> руб.
                        </span>
                    </>
                )}
            </Popover.Body>
        </Popover>
    );

    return (
        <div className={"fw-normal text-end " + (className || "")} style={{ textTransform: "none" }}>
            <Link href="/exchange/">
                <a className="header__shares-info">
                    Акции<span className="d-none d-sm-inline"> OK4U</span>: {sharesInfo.share_price} руб. /{" "}
                    {sharesInfo.dividends} руб.
                </a>
            </Link>{" "}
            <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={priceInfoPopover}>
                <a tabIndex="0" className="info-button bg-black text-white border-0" role="button">
                    ?
                </a>
            </OverlayTrigger>
        </div>
    );
}
