import React from "react";

import Link from "next/link";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import PlayerAccountSummary from "../playeraccountsummary";

function MenuItem({ item, menuPath }) {
    return (
        <li className={item.url.includes(menuPath) ? "active" : ""}>
            <Link href={item.url}>
                <a>{item.title}</a>
            </Link>
        </li>
    );
}

const LocationToggle = React.forwardRef(({ href, children, onClick }, ref) => (
    <a
        href={href}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

LocationToggle.displayName = "LocationToggle";

export default function SideMenu({
    menuItems,
    menuPath,
    show,
    onHide,
    onShowAuthModal,
    cities,
    currentCity,
    user,
    onLogoutClick,
}) {
    return (
        <Offcanvas show={show} onHide={onHide}>
            <Offcanvas.Header className="sidemenu__header">
                <Dropdown autoClose="outside">
                    <div className="sidemenu__location">
                        Регион:{" "}
                        <Dropdown.Toggle as={LocationToggle} href={`/${currentCity.slug}/`}>
                            <span>{currentCity.name}</span>
                        </Dropdown.Toggle>
                    </div>
                    <Dropdown.Menu className="header__location__cities">
                        {cities.map((city) => (
                            <Dropdown.Item href={city.url} style={{ color: "black" }} key={city.name}>
                                {city.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <button
                    type="button"
                    className="btn-close text-reset sidemenu__close"
                    onClick={onHide}
                    aria-label="Close"
                ></button>
            </Offcanvas.Header>
            <Offcanvas.Body className="sidemenu__body">
                {user && user.player ? (
                    <div className="sidemenu__account-summary">
                        <PlayerAccountSummary
                            player={user.player}
                            rating={user.rating}
                            inSideMenu
                            onLogoutClick={onLogoutClick}
                        />
                    </div>
                ) : (
                    <button
                        className="btn btn-outline-primary sidemenu__button sidemenu__button--outline"
                        onClick={onShowAuthModal}
                    >
                        Войти
                    </button>
                )}

                <Link href={`/${currentCity.slug}/enrollment/`}>
                    <a className="btn btn-primary sidemenu__button">Записаться на игру</a>
                </Link>

                <ul className="sidemenu__menu">
                    {menuItems.map((item) => (
                        <MenuItem item={item} menuPath={menuPath} key={item.title} />
                    ))}
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
