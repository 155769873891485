import { callAPIGet, callAPIJsonPost } from "../api";

export async function requestSMSCode(phone, message_media) {
    return callAPIJsonPost("/api/v1/auth/request-sms-code/", { phone, message_media });
}

export async function loginBySMSCode(phone, code) {
    return callAPIJsonPost("/api/v1/auth/login-sms-code/", { phone, code });
}

export async function logout() {
    const response = await callAPIGet("/api/v1/auth/logout/");
    return response.json();
}

export async function getAuthProps() {
    const response = await callAPIGet("/api/v1/auth/props/");
    return response.json();
}

export async function getMessageMedia(phone) {
    const response = await callAPIGet("/api/v1/auth/message-media/", { phone });
    return response.json();
}

export async function getTelegramLoginStartLink(phone) {
    const response = await callAPIGet("/api/v1/auth/telegram-start-link/", { phone });
    return response.json();
}
