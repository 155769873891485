import { useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Countdown from "./countdown";
import FormControlFeedback from "./controls/formcontrolfeedback";
import PinInput from "./controls/pininput";
import PrivacyNote from "./privacynote";
import { isPhoneValid } from "../lib/validation";
import {
    getAuthProps,
    requestSMSCode,
    loginBySMSCode,
    getMessageMedia,
    getTelegramLoginStartLink,
} from "../lib/api/auth";
import PhoneNumberInput from "./controls/phonenumberinput";

function normalizeErrorsRetryTime(errors) {
    // от сервера `retry_time` может приходить числом или числом в массиве.
    if (Array.isArray(errors.retry_time) && errors.retry_time.length > 0) {
        return { ...errors, retry_time: parseInt(errors.retry_time[0]) };
    }
    return errors;
}

function messageMediaName(media) {
    switch (media) {
        case "whatsapp":
            return "WhatsApp";
        case "telegram":
            return "Telegram";
        default:
            return "SMS";
    }
}

function MediaSelector({ messageMedia, setMessageMedia, telegramStartLink, disabled }) {
    const mediaChoices = ["whatsapp", "telegram"];
    return (
        <>
            <div className="d-flex justify-content-center mt-3">
                {mediaChoices.map((media) => (
                    <div className="form-check form-switch mx-2" key={media}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`login-message-media-${media}`}
                            checked={media == messageMedia}
                            disabled={disabled}
                            onChange={() => setMessageMedia(media)}
                        />
                        <label className="form-check-label" htmlFor={`login-message-media-${media}`}>
                            {messageMediaName(media)}
                        </label>
                    </div>
                ))}
            </div>
            {telegramStartLink && !disabled && (
                <div className="text-center mt-2">
                    Для отправки уведомлений через Telegram пройдите по{" "}
                    <a href={telegramStartLink} target="_blank" rel="noreferrer">
                        этой ссылке
                    </a>
                    , нажмите кнопку &quot;Start&quot; и подтвердите ваш номер телефона.
                </div>
            )}
        </>
    );
}

function AuthPhoneModal({
    title,
    phone,
    setPhone,
    initialMessageMedia,
    messageMedia,
    setMessageMedia,
    errors,
    setErrors,
    onSubmit,
    onHide,
}) {
    const [showCountdown, setShowCountdown] = useState(false);
    const [telegramStartLink, setTelegramStartLink] = useState(null);

    const loadTelegramStartLink = async () => {
        const linkData = await getTelegramLoginStartLink(phone);
        if (linkData.telegram_start_link) {
            setTelegramStartLink(linkData.telegram_start_link);
        } else {
            setTelegramStartLink(null);
        }
    };

    const onMediaSelect = (media) => {
        if (media == "telegram") {
            loadTelegramStartLink();
        } else {
            setTelegramStartLink(null);
        }
    };

    useEffect(() => {
        setShowCountdown(Boolean(errors.retry_time));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.retry_time]);

    useEffect(() => {
        const loadMessageMedia = async () => {
            const mediaData = await getMessageMedia(phone);
            if (mediaData.media) {
                setMessageMedia(mediaData.media);
                onMediaSelect(mediaData.media);
            }
        };
        setTelegramStartLink(null);
        setErrors({});
        if (isPhoneValid(phone)) {
            loadMessageMedia();
        } else {
            setMessageMedia(initialMessageMedia);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone]);

    useEffect(() => {
        setErrors({});
        onMediaSelect(messageMedia);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageMedia]);

    const countdownTime = useMemo(() => {
        return new Date().getTime() + parseInt(errors.retry_time) * 1000;
    }, [errors.retry_time]);

    const onClickPhone = (e) => {
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;
        if (selectionStart == selectionEnd && selectionStart < 4) {
            e.target.selectionStart = e.target.selectionEnd = 4;
        }
    };

    const onPhoneFormSubmit = async (e) => {
        e.preventDefault();
        await onSubmit();
    };

    const onCountdownExpire = () => {
        setShowCountdown(false);
        setErrors({ retry_time: 0 });
    };

    const buttonsDisabled = showCountdown || !isPhoneValid(phone);

    return (
        <Modal show centered className="modal--fancy modal--auth" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{title || "Войти по номеру телефона"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onPhoneFormSubmit}>
                    <Form.Group className="input-wrapper mb-3">
                        <PhoneNumberInput
                            phone={phone}
                            isInvalid={errors.phone}
                            onChange={setPhone}
                            onClick={onClickPhone}
                            size="lg"
                        />
                        <FormControlFeedback errors={errors.phone} />
                        <MediaSelector
                            messageMedia={messageMedia}
                            setMessageMedia={setMessageMedia}
                            telegramStartLink={telegramStartLink}
                            disabled={buttonsDisabled}
                        />
                        {showCountdown && (
                            <div className="mt-3 small">
                                <span className="text-secondary">Новый код можно получить через</span>{" "}
                                <Countdown targetDate={countdownTime} onExpire={onCountdownExpire} />
                            </div>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSubmit} disabled={buttonsDisabled || telegramStartLink != null}>
                    Выслать пароль по {messageMediaName(messageMedia)}
                </Button>
                <PrivacyNote />
            </Modal.Footer>
        </Modal>
    );
}

function AuthSMSCodeModal({ code, setCode, errors, setErrors, authProps, otherSites, onHide, onSubmit, onRetrySend }) {
    const [showCountdown, setShowCountdown] = useState(false);

    useEffect(() => {
        setShowCountdown(Boolean(errors.retry_time));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.retry_time]);

    const countdownTime = useMemo(() => {
        return new Date().getTime() + parseInt(errors.retry_time) * 1000;
    }, [errors.retry_time]);

    const onCodeFormSubmit = async (e) => {
        e.preventDefault();
        await onSubmit();
    };

    const onRetryClick = (e) => {
        e.preventDefault();
        onRetrySend();
    };

    const onCountdownExpire = () => {
        setShowCountdown(false);
        setErrors({ retry_time: 0, is_whatsapp: errors.is_whatsapp, is_telegram: errors.is_telegram });
    };

    let mediaName = "SMS";
    if (errors?.is_whatsapp) {
        mediaName = "WhatsApp";
    } else if (errors?.is_telegram) {
        mediaName = "Telegram";
    }

    return (
        <Modal show centered className="modal--fancy modal--auth" onHide={onHide}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="mt-0 mb-4 text-reset">Пароль из {mediaName}:</h4>
                <Form onSubmit={onCodeFormSubmit}>
                    <Form.Group className="sms-code mb-3">
                        <PinInput
                            characters={authProps.sms_code_length || 4}
                            containerClassName={errors.code || errors.phone ? "is-invalid" : null}
                            onChange={(value) => setCode(value)}
                        />
                        <FormControlFeedback errors={errors.code || errors.phone} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button variant="primary" onClick={onSubmit} disabled={code.length != authProps.sms_code_length}>
                        Войти
                    </Button>
                    <div className="mt-3 small">
                        {showCountdown ? (
                            <>
                                <span className="text-secondary">Новый код можно получить через</span>{" "}
                                <Countdown targetDate={countdownTime} onExpire={onCountdownExpire} />
                            </>
                        ) : (
                            <a href="" onClick={onRetryClick} className="text-secondary ">
                                Получить новый код
                            </a>
                        )}
                    </div>
                    {otherSites && otherSites.length > 0 && (
                        <div className="mt-3 px-2">
                            {otherSites.map((site, index) => (
                                <a
                                    href={site.url}
                                    className="d-inline-block my-1 mx-2"
                                    target="_blank"
                                    rel="noreferrer"
                                    key={`${index} ${site.name}`}
                                >
                                    {site.name}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default function AuthModal({ title, initialPhone, onHide, doNotHideOnLogin }) {
    const router = useRouter();

    const [authProps, setAuthProps] = useState({});
    const [initialMessageMedia, setInitialMessageMedia] = useState("telegram");

    const [phone, setPhone] = useState(initialPhone || "");
    const [code, setCode] = useState("");
    const [messageMedia, setMessageMedia] = useState("telegram");
    const [otherSites, setOtherSites] = useState([]);

    const [errors, setErrors] = useState({});
    const [codeRequested, setCodeRequested] = useState(false);

    const sendPhoneForm = async () => {
        const response = await requestSMSCode(phone, messageMedia);
        const json = await response.json();
        if (response.ok) {
            setErrors(normalizeErrorsRetryTime(json));
            setOtherSites(json.other_sites || []);
            setCodeRequested(true);
        } else {
            setErrors(normalizeErrorsRetryTime(json));
            setOtherSites([]);
        }
    };

    const sendCode = async () => {
        const response = await loginBySMSCode(phone, code, messageMedia);
        const json = await response.json();
        if (response.ok) {
            if (!doNotHideOnLogin) {
                onHide();
            }
            router.reload();
        } else {
            setErrors(normalizeErrorsRetryTime(json));
        }
    };

    useEffect(() => {
        async function loadAuthProps() {
            const props = await getAuthProps();
            setAuthProps(props);
            // setInitialMessageMedia(authProps.is_whatsapp_auth ? "whatsapp" : "telegram");
        }
        loadAuthProps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function callSendCode() {
            await sendCode();
        }
        if (authProps.sms_code_length && code && code.length == authProps.sms_code_length) {
            callSendCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    if (!authProps.sms_code_length) {
        return null;
    }

    if (codeRequested) {
        return (
            <AuthSMSCodeModal
                code={code}
                setCode={setCode}
                errors={errors}
                setErrors={setErrors}
                authProps={authProps}
                otherSites={otherSites}
                onSubmit={sendCode}
                onHide={onHide}
                onRetrySend={sendPhoneForm}
            />
        );
    } else {
        return (
            <AuthPhoneModal
                title={title}
                phone={phone}
                setPhone={setPhone}
                initialMessageMedia={initialMessageMedia}
                messageMedia={messageMedia}
                setMessageMedia={setMessageMedia}
                errors={errors}
                setErrors={setErrors}
                onSubmit={sendPhoneForm}
                onHide={onHide}
            />
        );
    }
}
