import { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import InputMask from "react-input-mask";

import {
    COUNTRIES_BY_PHONE_CODE,
    DEFAULT_NATIONAL_NUMBER_LENGTH,
    DEFAULT_PHONE_MASK,
    VALID_PHONE_CODES_BY_SIZE,
    VALID_PHONE_COUNTRIES,
} from "../../lib/phonecountries";
import { formatPhoneByMask } from "../../lib/phoneutils";

const PHONE_COUNTRY_CODE_STORAGE_KEY = "defaultPhoneCountryCode";

function CountryCodeSelector({ countryCode, setCountryCode, size }) {
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="no-light"
                id="dropdown-phone-country"
                className="px-2 py-2 shadow-none border-0 rounded fit-content"
            >
                {countryCode}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {VALID_PHONE_COUNTRIES.map((country) => (
                    <Dropdown.Item
                        key={country.code}
                        className="text-dark text-decoration-none"
                        onClick={() => setCountryCode(country.code)}
                    >
                        {country.code} {country.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function getCountryCodeAndPhone(phone) {
    const phoneDigits = (phone || "").replace(/[^\d]/g, "");

    if (phoneDigits === "" && typeof window !== "undefined") {
        phone = localStorage.getItem(PHONE_COUNTRY_CODE_STORAGE_KEY) || "";
    }

    const phoneE164 = "+" + (phone || "").replace(/[^\d]/g, "");

    for (const code of VALID_PHONE_CODES_BY_SIZE) {
        if (phoneE164.startsWith(code)) {
            const country = COUNTRIES_BY_PHONE_CODE[code];
            if (country) {
                return [country.code, phoneE164.substring(country.code.length), country.mask || DEFAULT_PHONE_MASK];
            }
        }
    }

    if (VALID_PHONE_COUNTRIES.length > 0) {
        const firstCountry = VALID_PHONE_COUNTRIES[0];
        return [
            firstCountry.code,
            phoneE164.substring(firstCountry.code.length),
            firstCountry.mask || DEFAULT_PHONE_MASK,
        ];
    }
    return ["+7", phoneE164.substring(2), DEFAULT_PHONE_MASK];
}

function stripCountryCode(countryCode, phone, altCountryCode) {
    let phoneDigits = (phone || "").replace(/[^\d]/g, "");

    const countryCodeDigits = (countryCode || "").replace(/[^\d]/g, "");
    while (countryCodeDigits != "" && phoneDigits.startsWith(countryCodeDigits)) {
        phoneDigits = phoneDigits.substring(countryCodeDigits.length);
    }

    const altCountryCodeDigits = (altCountryCode || "").replace(/[^\d]/g, "");
    while (altCountryCodeDigits != "" && phoneDigits.startsWith(altCountryCodeDigits)) {
        phoneDigits = phoneDigits.substring(altCountryCodeDigits.length);
    }

    return phoneDigits;
}

function InternationalPhoneNumberInput({ phone, placeholder, isInvalid, required, size, onChange, onClick }) {
    const [initialCountryCode, initialPhone, initialMask] = getCountryCodeAndPhone(phone);
    const [countryCode, setCountryCode] = useState(initialCountryCode);
    const [countryPhone, setCountryPhone] = useState(initialPhone);
    const [mask, setMask] = useState(initialMask);

    const maskChar = "_";
    const maskPlaceholder = placeholder || (mask || "").replace(/9/g, "_");

    const isInitialCountryCodeMount = useRef(true);
    const isInitialCountryPhoneMount = useRef(true);

    const onPhoneChanged = (newPhone) => {
        // console.log("newPhone", newPhone);
        onChange(newPhone);
    };

    // Эта функция нужна для того, чтобы нормально работал курсор
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        const { value, selection } = newState;
        const country = COUNTRIES_BY_PHONE_CODE[countryCode];

        const valueDigits = (value || "").replace(/[^\d]/g, "");
        const userInputDigits = (userInput || "").replace(/[^\d]/g, "");

        const phoneDigits = valueDigits.length > userInputDigits.length ? valueDigits : userInputDigits;
        const strippedPhone = stripCountryCode(countryCode, phoneDigits, country?.alt_code);

        if (phoneDigits != strippedPhone) {
            const { formattedPhone, cursorPosition } = formatPhoneByMask(strippedPhone, mask, maskPlaceholder);
            return { value: formattedPhone, selection: { start: cursorPosition, end: cursorPosition } };
        }
        return { value, selection };
    };

    const normalizePhone = () => {
        const country = COUNTRIES_BY_PHONE_CODE[countryCode];
        const numberLen = country?.nn_len || DEFAULT_NATIONAL_NUMBER_LENGTH;
        const phoneDigits = (countryPhone || "").replace(/[^\d]/g, "");
        const strippedPhone = stripCountryCode(countryCode, phoneDigits, country?.alt_code);
        const slicedCountryPhone = strippedPhone.slice(0, numberLen > 0 ? numberLen : 15);

        return {
            normalizedPhone: slicedCountryPhone,
            phoneAltered: slicedCountryPhone != phoneDigits,
            fullPhone: `${countryCode}${slicedCountryPhone}`,
        };
    };

    useEffect(() => {
        if (isInitialCountryPhoneMount.current) {
            isInitialCountryPhoneMount.current = false;
            return;
        }

        const { fullPhone } = normalizePhone();
        onPhoneChanged(fullPhone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryPhone]);

    useEffect(() => {
        const country = COUNTRIES_BY_PHONE_CODE[countryCode];
        setMask(country?.mask || DEFAULT_PHONE_MASK);

        if (isInitialCountryCodeMount.current) {
            isInitialCountryCodeMount.current = false;
            return;
        }

        const { normalizedPhone, phoneAltered, fullPhone } = normalizePhone();
        if (phoneAltered) {
            setCountryPhone(normalizedPhone); // onChange вызовется уже в том useEffect
        } else {
            onPhoneChanged(fullPhone);
        }

        if (typeof window !== "undefined") {
            localStorage.setItem(PHONE_COUNTRY_CODE_STORAGE_KEY, countryCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    return (
        <div className="d-flex align-items-center">
            <CountryCodeSelector countryCode={countryCode} setCountryCode={setCountryCode} size={size} />
            <InputMask
                mask={mask}
                value={countryPhone}
                placeholder={maskPlaceholder}
                maskChar={maskChar}
                isInvalid={isInvalid}
                required={required}
                beforeMaskedValueChange={beforeMaskedValueChange}
                onChange={(e) => setCountryPhone(e.target.value)}
                onClick={onClick}
            >
                {(inputProps) => <Form.Control {...inputProps} type="tel" size={size || "lg"} />}
            </InputMask>
        </div>
    );
}

function SimplePhoneNumberInput({ phone, placeholder, isInvalid, required, size, onChange, onClick }) {
    return (
        <InputMask
            mask="+7 (999) 999-99-99"
            value={phone}
            placeholder={placeholder || "+7 (___) ___-__-__"}
            isInvalid={isInvalid}
            required={required}
            onChange={(e) => onChange(e.target.value)}
            onClick={onClick}
        >
            {(inputProps) => <Form.Control {...inputProps} type="tel" size={size || "lg"} />}
        </InputMask>
    );
}

export default function PhoneNumberInput({ phone, placeholder, isInvalid, required, size, onChange, onClick }) {
    if (process.env.NEXT_PUBLIC_INTL_PHONE_CODES) {
        return (
            <div className={"phone-number-input" + (isInvalid ? " is-invalid" : "")}>
                <InternationalPhoneNumberInput
                    phone={phone}
                    placeholder={placeholder}
                    isInvalid={isInvalid}
                    required={required}
                    size={size}
                    onChange={onChange}
                    onClick={onClick}
                />
            </div>
        );
    }

    return (
        <div className={"phone-number-input" + (isInvalid ? " is-invalid" : "")}>
            <SimplePhoneNumberInput
                phone={phone}
                placeholder={placeholder}
                isInvalid={isInvalid}
                required={required}
                size={size}
                onChange={onChange}
                onClick={onClick}
            />
        </div>
    );
}
