import Form from "react-bootstrap/Form";

export default function FormControlFeedback({ errors }) {

    if (!errors) {
        return null;
    }

    return (
        <Form.Control.Feedback type="invalid">
            {errors.map((error) => (
                <div key={error}>{error}</div>
            ))}
        </Form.Control.Feedback>
    );
}
