import {
    COUNTRIES_BY_PHONE_CODE,
    DEFAULT_NATIONAL_NUMBER_LENGTH,
    MIN_INTL_NUMBER_LENGTH,
    VALID_PHONE_CODES,
} from "./phonecountries";

export function isPhoneValid(phone) {
    if (!phone) {
        return false;
    }
    const normalizedPhone = "+" + phone.replace(/\D/g, "");
    if (normalizedPhone == "+") {
        return false;
    }

    for (const code of VALID_PHONE_CODES) {
        if (!normalizedPhone.startsWith(code)) {
            continue;
        }
        const country = COUNTRIES_BY_PHONE_CODE[code];
        if (!country) {
            continue;
        }

        const nationalNumberLen = country.nn_len || DEFAULT_NATIONAL_NUMBER_LENGTH;
        if (nationalNumberLen == -1) {
            return normalizedPhone.length > MIN_INTL_NUMBER_LENGTH;
        }

        return normalizedPhone.length == code.length + nationalNumberLen;
    }
    return false;
}

export function isEqualPhone(phone1, phone2) {
    if (!phone1 || !phone2) {
        return false;
    }
    const digits1 = phone1.replace(/\D/g, "");
    const digits2 = phone2.replace(/\D/g, "");
    return digits1 == digits2;
}

export function isEmptyObj(obj) {
    return Object.keys(obj).length == 0;
}
