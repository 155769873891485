import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import PrivacyNote from "../privacynote";

import FormControlFeedback from "../controls/formcontrolfeedback";
import { getPhoneDigits } from "../../lib/content";

import { submitFranchiseeRequest } from "../../lib/api/franchisee";
import { onFranchiseeRequest } from "../../lib/api/gtm";
import PhoneNumberInput from "../controls/phonenumberinput";

function FranchiseeRequestSuccessModal({ title, onHide }) {
    return (
        <Modal show centered className="modal--fancy modal--auth" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Ваша заявка отправлена</h4>
                <p className="mb-4">В ближайшее время мы с вами свяжемся.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="mb-3" onClick={onHide}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function FranchiseeRequestModal({ title, reqTelegram, reqPhone, onHide }) {
    const franchiseeReqTelegram = reqTelegram;
    const franchiseeReqPhoneDigits = getPhoneDigits(reqPhone);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [requestSent, setRequestSent] = useState(false);
    const [errors, setErrors] = useState({});

    const onSubmit = async () => {
        const response = await submitFranchiseeRequest(name, phone, document.title, document.URL);
        const json = await response.json();
        if (response.ok) {
            setRequestSent(true);
            onFranchiseeRequest();
        } else {
            setErrors(json);
        }
    };

    if (requestSent) {
        return <FranchiseeRequestSuccessModal title={title} onHide={onHide} />;
    }

    return (
        <Modal show centered className="modal--fancy modal--auth" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="input-wrapper">
                        <Form.Control
                            type="text"
                            size="lg"
                            value={name}
                            placeholder="Имя"
                            isInvalid={errors.name}
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormControlFeedback errors={errors.name} />
                    </Form.Group>
                    <Form.Group className="input-wrapper mb-3">
                        <PhoneNumberInput
                            phone={phone}
                            isInvalid={errors.phone}
                            required={true}
                            onChange={setPhone}
                            size="lg"
                        />
                        <FormControlFeedback errors={errors.phone} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSubmit}>
                    Отправить
                </Button>
                <div className="enrollment-modal__hr mt-3">
                    <span>или</span>
                </div>

                <div className="enrollment-modal__social">
                    <a href={franchiseeReqTelegram} target="_blank" rel="noreferrer">
                        <img src="/img/telegram-color.svg" width="32" height="32" alt="" />
                        <span>Заявка через Telegram</span>
                    </a>
                    <a href={`https://wa.me/${franchiseeReqPhoneDigits}`} target="_blank" rel="noreferrer">
                        <img src="/img/whatsapp-color.svg" width="32" height="32" alt="" />
                        <span>Заявка через WhatsApp</span>
                    </a>
                </div>

                <div className="w-100 m-0">
                    <PrivacyNote />
                </div>
            </Modal.Footer>
        </Modal>
    );
}
