import { callAPIGet, callAPIJsonPost } from "../api";

export async function fetchGamePageProps(city, id, cookies) {
    return callAPIGet(`/api/v1/pages/game/${id}/`, { city }, { cookies });
}

export async function fetchPlacePageProps(slug, cookies) {
    return callAPIGet(`/api/v1/pages/place/`, { slug }, { cookies });
}

export async function fetchPlayerPageProps(id, cookies) {
    const response = await callAPIGet(`/api/v1/pages/player/${id}/`, {}, { cookies });
    return response.json();
}

export async function fetchEnrollmentPageProps(city, date, place, game_type, game_level, club, cookies) {
    const response = await callAPIGet(
        "/api/v1/pages/enrollment/",
        { city, date, place, game_type, game_level, club },
        { cookies }
    );
    return response.json();
}

export async function fetchTournamentPageProps(city, id) {
    const response = await callAPIGet(`/api/v1/pages/tournament/${id}/`, { city });
    return response.json();
}

export async function fetchRatingTournamentPageProps(city, id) {
    return callAPIGet(`/api/v1/pages/ratingtournament/${id}/`, { city });
}

export async function fetchTournament3PageProps(city, yearMonth, gameType, cookies) {
    return callAPIGet(`/api/v1/pages/tournament3/${yearMonth}/`, { city, game_type: gameType }, { cookies });
}

export async function fetchRatingPageProps(city, page) {
    const response = await callAPIGet("/api/v1/pages/rating/", { city, page });
    return response.json();
}

export async function fetchRating2PageProps(city, page, game_type, order_by, cookies) {
    const response = await callAPIGet("/api/v1/pages/rating2/", { city, page, game_type, order_by }, { cookies });
    return response.json();
}

export async function fetchArchivePageProps(city, page, type, cookies) {
    const response = await callAPIGet("/api/v1/pages/archive/", { city, page, type }, { cookies });
    return response.json();
}

export async function fetchRunningGames(city) {
    const response = await callAPIGet("/api/v1/enrollment/running/", { city });
    return response.json();
}

export async function fetchEnrollmentGames(city) {
    const response = await callAPIGet("/api/v1/enrollment/", { city });
    return response.json();
}

export async function fetchEnrollmentGamesForHome(city, date, game_type, host_level, place, game_level, club) {
    const response = await callAPIGet("/api/v1/enrollment/home/", {
        city,
        date,
        game_type,
        host_level,
        place,
        game_level,
        club,
    });
    return response.json();
}

export async function fetchArchivedGamesForHome(city, game_type) {
    const response = await callAPIGet("/api/v1/enrollment/home-archive/", { city, game_type });
    return response.json();
}

export async function fetchPlayerSignedUpGames(player) {
    const response = await callAPIGet(`/api/v1/player/signed-up-games/`, { player });
    return response.json();
}

export async function fetchPlayerArchivedGames(player, offset) {
    const response = await callAPIGet(`/api/v1/player/archived-games/`, { player, offset });
    return response.json();
}

export async function fetchHostGames(player, host_game_relation, offset) {
    const response = await callAPIGet(`/api/v1/player/host-games/`, { player, host_game_relation, offset });
    return response.json();
}

export async function fetchUnattendedGames(player) {
    const response = await callAPIGet(`/api/v1/player/unattended-games/`, { player });
    return response.json();
}

export async function fetchEnrollmentProps(game) {
    const data = game ? { game: game.id } : {};
    const response = await callAPIGet("/api/v1/enrollment/props/", data);
    return response.json();
}

export async function fetchEnrollmentPaymentInfo(game, enrollmentType, phones, useDiscount, couponCode) {
    const data = { phones, enrollment_type: enrollmentType || "" };
    if (game) {
        data.game = game.id;
    }
    if (useDiscount) {
        data.use_discount = true;
    }
    if (couponCode) {
        data.coupon_code = couponCode;
    }
    const response = await callAPIJsonPost("/api/v1/enrollment/payment-info/", data);
    return response.json();
}

export async function enrollToGame({
    gameId,
    players,
    enrollmentType,
    useBalance,
    useDiscount,
    codeWord,
    couponCode,
    askForCodeWord,
    teamId,
    teamName,
    teamCodeWord,
    askForTeamCodeWord,
}) {
    const data = { players, enrollment_type: enrollmentType || "" };
    if (gameId) {
        data.game = gameId;
    }
    if (useBalance) {
        data.use_balance = true;
    }
    if (useDiscount) {
        data.use_discount = true;
    }
    if (codeWord) {
        data.code_word = codeWord;
    }
    if (askForCodeWord) {
        data.ask_for_code_word = true;
    }
    if (couponCode) {
        data.coupon_code = couponCode;
    }
    if (teamId) {
        data.team_id = teamId;
    }
    if (teamName) {
        data.team_name = teamName;
    }
    if (teamCodeWord) {
        data.team_code_word = teamCodeWord;
    }
    if (askForTeamCodeWord) {
        data.ask_for_team_code_word = askForTeamCodeWord;
    }
    return callAPIJsonPost("/api/v1/enrollment/", data);
}

export async function cancelEnrollment(gameId) {
    const response = await callAPIJsonPost("/api/v1/enrollment/cancel/", { game: gameId });
    return response.json();
}

export async function addToWaitingList(gameId, phone) {
    const data = { game: gameId, phone };
    return callAPIJsonPost("/api/v1/enrollment/add-waiting-list/", data);
}

export async function fetchUserEnrollments() {
    const response = await callAPIGet("/api/v1/enrollment/user/");
    return response.json();
}

export async function fetchCurrentTournament(city) {
    const response = await callAPIGet(`/api/v1/tournament/current/`, { city });
    return response.json();
}

export async function fetchCurrentRatingTournament(city) {
    const response = await callAPIGet(`/api/v1/ratingtournament/current/`, { city });
    return response.json();
}

export async function fetchCurrentTournament3(city) {
    const response = await callAPIGet(`/api/v1/tournament3/current/`, { city });
    return response.json();
}

export async function fetchGame(city, id) {
    const response = await callAPIGet(`/api/v1/game/${id}/`, { city });
    return response.json();
}

export async function fetchHosts(city, cookies) {
    const response = await callAPIGet("/api/v1/player/hosts/", { city }, { cookies });
    return response.json();
}

export async function fetchAvailableHosts(city, date, game_type, host_level) {
    const response = await callAPIGet("/api/v1/pages/hosts-availability/available/", {
        city,
        date,
        game_type,
        host_level,
    });
    return response.json();
}

export async function fetchAvailableHosts2(city, date, game_type, host_level) {
    const response = await callAPIGet("/api/v1/pages/hosts-availability2/available/", {
        city,
        date,
        game_type,
        host_level,
    });
    return response.json();
}

export async function fetchHostsAvailability(city, cookies) {
    return callAPIGet("/api/v1/pages/hosts-availability/", { city }, { cookies });
}

export async function fetchHostsAvailability2(city, cookies) {
    return callAPIGet("/api/v1/pages/hosts-availability2/", { city }, { cookies });
}

export async function fetchPlayers(city, listId, cookies) {
    const params = listId ? { list: listId } : {};
    const response = await callAPIGet("/api/v1/player/players/", { city, ...params }, { cookies });
    return response.json();
}

export async function fetchBirthdays(city, past) {
    const params = past ? { past } : {};
    const response = await callAPIGet("/api/v1/player/birthdays/", { city, ...params });
    return response.json();
}

export async function fetchGalleryPageProps(city) {
    const response = await callAPIGet("/api/v1/pages/gallery/", { city });
    return response.json();
}

export async function fetchGallery(city, month) {
    const response = await callAPIGet("/api/v1/gamegallery/", { city, month });
    return response.json();
}

export async function updateLoggedPlayerAvatar(avatarFile) {
    const formData = new FormData();
    formData.append("avatar", avatarFile);
    return callAPIJsonPost("/api/v1/player/update-avatar/", formData, true);
}

export async function updateLoggedPlayerDescription(description) {
    return callAPIJsonPost("/api/v1/player/update-description/", { description });
}

export async function updateLoggedPlayerInstagram(instagram) {
    return callAPIJsonPost("/api/v1/player/update-instagram/", { instagram });
}

export async function updateLoggedPlayerMafiaNick(mafia_nick) {
    return callAPIJsonPost("/api/v1/player/update-mafia-nick/", { mafia_nick });
}

export async function updateLoggedPlayerTelegram(telegram_username) {
    return callAPIJsonPost("/api/v1/player/update-telegram/", { telegram_username });
}

export async function updateLoggedPlayerName(name) {
    return callAPIJsonPost("/api/v1/player/update-name/", { name });
}

export async function updateLoggedPlayerBirthDate(birth_date, is_age_hidden) {
    return callAPIJsonPost("/api/v1/player/update-birth-date/", { birth_date, is_age_hidden });
}

export async function updateLoggedNotificationMedia(notification_media) {
    return callAPIJsonPost("/api/v1/player/update-notification-media/", { notification_media });
}

export async function updateLoggedIsSubscribedToNewsletters(is_subscribed_to_newsletters) {
    return callAPIJsonPost("/api/v1/player/update-is-subscribed-to-newsletters/", { is_subscribed_to_newsletters });
}

export async function updateLoggedReceiveLikedPlayersEnrollments(receive_liked_players_enrollments) {
    return callAPIJsonPost("/api/v1/player/update-receive-liked-players-enrollments/", {
        receive_liked_players_enrollments,
    });
}

export async function updateLoggedSendEnrollmentsToLikedPlayers(send_enrollments_to_liked_players) {
    return callAPIJsonPost("/api/v1/player/update-send-enrollments-to-liked-players/", {
        send_enrollments_to_liked_players,
    });
}

export async function updateLoggedGender(gender) {
    return callAPIJsonPost("/api/v1/player/update-gender/", { gender });
}

export async function fetchCodeWordRequest(code, cookies) {
    return callAPIGet(`/api/v1/codeword/${code}/`, {}, { cookies });
}

export async function shareCodeWord(code) {
    return callAPIJsonPost(`/api/v1/codeword/${code}/share/`);
}
