import Cookies from "cookies";

export function tenYearsFromNow() {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    return date;
}

function isUTMParam(param) {
    return param == "p" || param.startsWith("utm_");
}

function UTMParamToKey(param) {
    if (param == "p") {
        return param;
    }
    return param.substring(4); // отбрасываем 'utm_'
}

function getUTMData(query) {
    const utmParams = Object.keys(query).filter(isUTMParam);
    if (utmParams.length == 0) {
        return {};
    }
    return Object.fromEntries(utmParams.map((param) => [UTMParamToKey(param), encodeURIComponent(query[param])]));
}

function setUtmFirstCookies(req, res) {
    const cookies = new Cookies(req, res);
    const utm = cookies.get("cf_utm");
    const utmFirst = cookies.get("cf_utm_first");
    if (utm && !utmFirst) {
        cookies.set("cf_utm_first", utm, { domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN, expires: tenYearsFromNow() });
    }
}

export function handleUTMParams(query, req, res, utmTtlMinutes) {
    if (!req) {
        return;
    }

    const utmData = getUTMData(query);
    if (Object.keys(utmData).length === 0) {
        setUtmFirstCookies(req, res);
        return;
    }

    // utm-метки в url есть
    const utmCookie = JSON.stringify(utmData);
    const expireDate = new Date();
    expireDate.setMinutes(expireDate.getMinutes() + utmTtlMinutes);

    const cookies = new Cookies(req, res);
    cookies.set("cf_utm", utmCookie, {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        expires: expireDate,
    });
    const utmFirst = cookies.get("cf_utm_first");
    if (!utmFirst) {
        cookies.set("cf_utm_first", utmCookie, {
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            expires: tenYearsFromNow(),
        });
    }
}
