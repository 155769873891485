import React from "react";
import { useState } from "react";

import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";

import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import AuthModal from "../authmodal";
import Avatar from "../avatar";
import Picture from "../picture";
import PlayerAccountSummary from "../playeraccountsummary";
import SideMenu from "./sidemenu";

import { logout } from "../../lib/api/auth";
import SharesInfo from "./sharesinfo";
import { isMeet4USite } from "../../lib/coreutils";

function MenuItem({ item, menuPath }) {
    return (
        <li className={item.url.includes(menuPath) ? "active" : ""}>
            <Link href={item.url}>
                <a>{item.title}</a>
            </Link>
        </li>
    );
}

const LocationToggle = React.forwardRef(({ href, children, onClick }, ref) => (
    <a
        href={href}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

LocationToggle.displayName = "LocationToggle";

export default function Header({ menuItems, menuPath, cities, currentCity, user, sharesInfo, isDark }) {
    const [sideMenuVisible, setSideMenuVisible] = useState(false);
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const router = useRouter();

    const headerClass = "container-fluid header " + (isDark ? "header--dark" : "");

    const showSideMenu = (event) => {
        event.preventDefault();
        setSideMenuVisible(true);
    };

    const showAuthModal = (event) => {
        event.preventDefault();
        setAuthModalVisible(true);
    };

    const onLogoutClick = async (event) => {
        event.preventDefault();
        await logout();
        router.reload();
    };

    const popupClassName = "header__account-summary" + (isDark ? " header__account-summary--dark" : "");

    const playerSummaryPopover =
        user && user.player ? (
            <Popover id="popover-header-account-summary" className={popupClassName}>
                <Popover.Body>
                    <div>
                        <PlayerAccountSummary player={user.player} rating={user.rating} />
                    </div>
                </Popover.Body>
            </Popover>
        ) : null;

    const topMenu = menuItems.filter((item) => item.show_in_top);

    return (
        <>
            <Head>
                <link rel="icon" href={process.env.NEXT_PUBLIC_FAVICON || "/favicon.ico"} type="image/x-icon" />
            </Head>

            <header className={headerClass}>
                <a
                    className="header__hamburger"
                    href="#sidemenu"
                    role="button"
                    aria-controls="sidemenu"
                    onClick={showSideMenu}
                >
                    <img src="/img/icons/icon-hamburger.svg" alt="Меню" width={17} height={16} />
                </a>
                <div className={"header__logo" + (process.env.NEXT_PUBLIC_LOGO ? " header__logo--custom" : "")}>
                    <Link href={`/${currentCity.slug}/`}>
                        <a>
                            {process.env.NEXT_PUBLIC_LOGO ? (
                                <img
                                    src={isDark ? process.env.NEXT_PUBLIC_LOGO_DARK : process.env.NEXT_PUBLIC_LOGO}
                                    style={{ height: `${process.env.NEXT_PUBLIC_LOGO_HEIGHT}px` }}
                                    alt="CASHFLOW"
                                />
                            ) : (
                                <Picture
                                    baseUrl="/img/logo/cashflow-logo"
                                    width={198}
                                    height={65}
                                    alt="CASHFLOW"
                                    isPng
                                />
                            )}
                        </a>
                    </Link>
                </div>

                {!isMeet4USite() && (
                    <Dropdown autoClose="outside">
                        <div className="header__location">
                            <Dropdown.Toggle as={LocationToggle} href={`/${currentCity.slug}/`}>
                                <span>{currentCity.name}</span>
                            </Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu className="header__location__cities">
                            {cities.map((city) => (
                                <Dropdown.Item href={city.url} style={{ color: "black" }} key={city.name}>
                                    {city.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                <ul className="header__menu">
                    {topMenu.map((item) => (
                        <MenuItem item={item} menuPath={menuPath} key={item.title} />
                    ))}
                    <li className="ms-auto"></li>
                    <li>
                        <SharesInfo sharesInfo={sharesInfo} isDark={isDark} />
                    </li>
                    <li>
                        {user && user.player ? (
                            <>
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="bottom"
                                    overlay={playerSummaryPopover}
                                >
                                    <a href={user.player.url} className="header__menu__avatar">
                                        <Avatar player={user.player} size={32} noLink />
                                    </a>
                                </OverlayTrigger>
                                <a href="" className="btn btn-sm header__login-btn ms-3" onClick={onLogoutClick}>
                                    Выйти
                                </a>
                            </>
                        ) : (
                            <a href="" className="btn btn-sm header__login-btn" onClick={showAuthModal}>
                                Войти
                            </a>
                        )}
                    </li>
                </ul>
            </header>

            <div
                className={
                    "container-fluid d-flex align-items-center justify-content-end d-sm-none" +
                    (isDark ? " header--dark" : "")
                }
            >
                <SharesInfo sharesInfo={sharesInfo} className="me-3" isDark={isDark} />

                {user && user.player ? (
                    <>
                        <Link href={user.player.url}>
                            <a className="header__menu__avatar">
                                <Avatar player={user.player} size={32} noLink />
                            </a>
                        </Link>
                        <a href="" className="btn btn-sm header__login-btn ms-3" onClick={onLogoutClick}>
                            Выйти
                        </a>
                    </>
                ) : (
                    <a href="" className="btn btn-sm header__login-btn me-3" onClick={showAuthModal}>
                        Войти
                    </a>
                )}
            </div>

            <SideMenu
                menuItems={menuItems.filter((item) => item.show_in_mob)}
                menuPath={menuPath}
                cities={cities}
                currentCity={currentCity}
                user={user}
                show={sideMenuVisible}
                onHide={() => setSideMenuVisible(false)}
                onShowAuthModal={showAuthModal}
                onLogoutClick={onLogoutClick}
            />
            {authModalVisible && <AuthModal onHide={() => setAuthModalVisible(false)} />}
        </>
    );
}
