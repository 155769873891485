import Link from "next/link";

import Picture from "../picture";

function BottomMenuBlock({ title, menuItems }) {
    return (
        <div className={"footer__menu-block" + (title ? "" : " footer__menu-block--legal")}>
            <div className="h3">{title ? title : <>Все права защищены &copy; {new Date().getFullYear()}</>}</div>
            {menuItems.map((item) => (
                <div className="footer__menu-item" key={item.url}>
                    <Link href={item.url}>
                        <a>{item.title}</a>
                    </Link>
                </div>
            ))}
        </div>
    );
}

function FooterLogo({ currentCity }) {
    return (
        <div className="footer__menu-block footer__menu-block--logo">
            <span className={"footer__logo" + (process.env.NEXT_PUBLIC_LOGO ? " footer__logo--custom" : "")}>
                <Link href={`/${currentCity.slug}/`}>
                    <a>
                        {process.env.NEXT_PUBLIC_LOGO ? (
                            <img
                                src={process.env.NEXT_PUBLIC_LOGO}
                                style={
                                    {
                                        /* height: `${process.env.NEXT_PUBLIC_LOGO_HEIGHT}px` */
                                    }
                                }
                                alt="CASHFLOW"
                            />
                        ) : (
                            <Picture baseUrl="/img/logo/cashflow-logo" width={213} height={70} alt="CASHFLOW" isPng />
                        )}
                    </a>
                </Link>
            </span>
        </div>
    );
}

export default function Footer({ bottomMenuBlocks, currentCity }) {
    return (
        <footer className="container-fluid footer">
            <div className="footer__menu">
                <FooterLogo currentCity={currentCity} />
                {bottomMenuBlocks.map((block) => (
                    <BottomMenuBlock key={block.title} title={block.title} menuItems={block.menu} />
                ))}
            </div>
        </footer>
    );
}
