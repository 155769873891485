import Link from "next/link";

import Avatar from "./avatar";

export default function PlayerAccountSummary({ player, rating, inSideMenu, onLogoutClick }) {
    return (
        <div className="player-account-summary">
            <div className="player-account-summary__player">
                <div className="player-account-summary__avatar">
                    <Avatar player={player} size={36} />
                </div>
                <Link href={player.url}>
                    <a className="player-account-summary__name">{player.name}</a>
                </Link>
            </div>
            {rating && (
                <div className="player-account-summary__counts">
                    <div className="player-account-summary__count">
                        <div className="player-account-summary__number">
                        {rating.place ? `#${rating.place}` : "-"}</div>
                        рейтинг
                    </div>
                    <div className="player-account-summary__count">
                        <div className="player-account-summary__number">{rating.num_games}</div>
                        всего игр
                    </div>
                    <div className="player-account-summary__count">
                        <div className="player-account-summary__number">{rating.num_wins}</div>
                        побед
                    </div>
                </div>
            )}
            {inSideMenu && (
                <div className="player-account-summary__logout">
                    <a
                        href=""
                        className="btn btn-outline-primary sidemenu__button sidemenu__button--outline"
                        onClick={onLogoutClick}
                    >
                        Выйти
                    </a>
                </div>
            )}
        </div>
    );
}
