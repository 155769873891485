export function formatPhoneByMask(phone, mask, maskPlaceholder) {
    let formattedPhone = "";

    let phonePosition = 0;
    let cursorPosition = 0;
    for (let i = 0; i < mask.length; i++) {
        const char = mask.charAt(i);
        if (char == "9") {
            const phoneChar = phone.charAt(phonePosition);
            if (phoneChar === "") {
                formattedPhone += maskPlaceholder.charAt(i);
            } else {
                formattedPhone += phoneChar;
                phonePosition++;
                cursorPosition++;
            }
        } else {
            formattedPhone += char;
            if (phonePosition < phone.length || cursorPosition == 0) {
                cursorPosition++;
            }
        }
    }

    return {
        formattedPhone: formattedPhone,
        cursorPosition: cursorPosition,
    };
}
