export async function callAPIGet(apiUrl, data, options) {
    const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL;
    const params = data ? "?" + new URLSearchParams(data).toString() : "";
    const fetchOptions = { headers: {}, credentials: "include" };
    if (options) {
        const { cookies } = options;
        if (cookies) {
            fetchOptions.headers.cookie = Object.entries(cookies)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("; ");
        }
    }
    return fetch(`${backendUrl}${apiUrl}${params}`, fetchOptions);
}

export async function callAPIJsonPost(apiUrl, data, isFormData) {
    const fetchOptions = {
        method: "POST",
        headers: {},
        credentials: "include",
        body: isFormData ? data : JSON.stringify(data),
    };

    if (!isFormData) {
        fetchOptions.headers["Content-Type"] = "application/json";
    }

    if (typeof window !== "undefined") {
        const csrfEl = document.getElementById("csrftoken");
        if (csrfEl) {
            fetchOptions.headers["X-CSRFToken"] = csrfEl.value;
        }
    }

    const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL;
    return fetch(`${backendUrl}${apiUrl}`, fetchOptions);
}


export async function callAPIJsonPut(apiUrl, data, isFormData) {
    const fetchOptions = {
        method: "PUT",
        headers: {},
        credentials: "include",
        body: isFormData ? data : JSON.stringify(data),
    };

    if (!isFormData) {
        fetchOptions.headers["Content-Type"] = "application/json";
    }

    if (typeof window !== "undefined") {
        const csrfEl = document.getElementById("csrftoken");
        if (csrfEl) {
            fetchOptions.headers["X-CSRFToken"] = csrfEl.value;
        }
    }

    const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL;
    return fetch(`${backendUrl}${apiUrl}`, fetchOptions);
}
