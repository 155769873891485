import Cookies from "cookies";

export function urlToMedia(url) {
    if (!url) {
        return url;
    }
    if (url.startsWith("http")) {
        return url;
    }
    const mediaUrl = process.env.NEXT_PUBLIC_MEDIA_URL;
    return `${mediaUrl}${url}`;
}

export function getSnippetContent(snippets, slug) {
    if (!snippets) {
        return "";
    }
    const snippet = snippets.find((s) => s.slug == slug);
    if (!snippet) {
        return "";
    }
    if (snippet.snippet_type == "image") {
        return snippet;
    }
    return snippet.snippet_type == "html" ? snippet.html : snippet.text;
}

export function getGameCitySlug(game) {
    const bits = game.url.split("/");
    return bits[1];
}

export function getPhoneDigits(phone, withPlus) {
    if (!phone) {
        return null;
    }
    const digits = phone.replace(/\D/g, "");
    return withPlus ? `+${digits}` : digits;
}

export function numberWithSpaces(number) {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getPercentage(part, whole) {
    let percentage = 0.0;
    if (whole) {
        percentage = (100 * parseFloat(part)) / parseFloat(whole);
    }
    return percentage.toFixed(2);
}

export function getParticipantAvatarClassName(participant) {
    const player = participant.player;
    return (
        (participant.place ? ` place-${participant.place}` : "") +
        (player?.no_contact === true ? " no-contact" : "") +
        (participant.is_paid === false ? " not-paid" : "") +
        (player?.is_candidate === true && player?.no_host_border !== true ? " candidate" : "") +
        (player?.is_host === true && player?.no_host_border !== true ? " host" : "") +
        (player?.is_special === true ? " special" : "")
    );
}

export function CopyToClipboard(value) {
    const temp = document.createElement("textarea");
    document.body.appendChild(temp);
    temp.value = value;
    temp.select();
    document.execCommand("copy");
    temp.remove();
}

export function setPromocodeCookie(query, req, res) {
    if (!req) {
        return;
    }
    const promocode = query["p"];
    if (promocode) {
        let oneMonthFromNow = new Date();
        oneMonthFromNow.setDate(oneMonthFromNow.getDate() + 30);
        const cookies = new Cookies(req, res);
        cookies.set("p", encodeURIComponent(promocode), {
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            expires: oneMonthFromNow,
        });
    }
}

export function collapseText(text, maxLength, maxLines) {
    if (!text) {
        return "";
    }
    let addEllipsis = false;
    const lines = text.split("\n");
    if (lines.length > maxLines) {
        text = lines.slice(0, maxLines).join("\n");
        addEllipsis = true;
    }
    if (text.length > maxLength) {
        text = text.slice(0, maxLength);
        addEllipsis = true;
    }
    if (addEllipsis) {
        text += "...";
    }
    return text;
}
