// Based on https://github.com/drac94/react-auth-code-input/

import { useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";

export default function PinInput({
    allowedCharacters = "^[0-9]*$",
    characters,
    containerClassName,
    inputClassName,
    inputType = "tel",
    onChange,
}) {
    const inputsRef = useRef([]);

    useEffect(() => {
        inputsRef.current[0].focus();
    }, []);

    const sendResult = () => {
        const res = inputsRef.current.map((input) => input.value).join("");
        onChange && onChange(res);
    };

    const handleOnChange = (e) => {
        if (e.target.value.match(allowedCharacters)) {
            if (e.target.nextElementSibling !== null) {
                e.target.nextElementSibling.focus();
            }
        } else {
            e.target.value = "";
        }
        sendResult();
    };

    const handleOnKeyDown = (e) => {
        const { key } = e;
        const target = e.target;
        if (key === "Backspace") {
            target.value = "";
            if (target.previousElementSibling !== null) {
                target.previousElementSibling.focus();
                e.preventDefault();
            }
            sendResult();
        }
    };

    const handleOnFocus = (e) => {
        e.target.select();
    };

    const handleOnPaste = (e) => {
        const value = e.clipboardData.getData("Text");
        if (value.match(allowedCharacters)) {
            for (let i = 0; i < characters && i < value.length; i++) {
                inputsRef.current[i].value = value.charAt(i);
                if (inputsRef.current[i].nextElementSibling !== null) {
                    inputsRef.current[i].nextElementSibling.focus();
                }
            }
            sendResult();
        }
        e.preventDefault();
    };

    const inputs = [];
    for (let i = 0; i < characters; i++) {
        inputs.push(
            <Form.Control
                size="lg"
                key={i}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onFocus={handleOnFocus}
                onPaste={handleOnPaste}
                type={inputType}
                ref={(el) => (inputsRef.current[i] = el)}
                maxLength={1}
                className={inputClassName}
            />
        );
    }

    return <div className={"d-flex justify-content-center " + (containerClassName || "")}>{inputs}</div>;
}
