import Head from "next/head";

import parse from "html-react-parser";

export default function Meta({ headerCounters, title, metrikaId, vkPixelId, user }) {

    const windowUserId = `window.USER_ID = "${user?.id || ''}";`;

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Roboto+Condensed:wght@300;400;700&display=swap"
                rel="stylesheet"
            />

            <link rel="preconnect" href={process.env.NEXT_PUBLIC_MEDIA_URL} />
            <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_MEDIA_URL} />

            {false &&
                <meta property="og:image" content="/img/cashflow-logo-opengraph.jpg" />
            }

            <title>{title}</title>

            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: windowUserId }} />
            <script type="text/javascript">
                window.dataLayer = window.dataLayer || [];
                window.METRIKA_ID = {metrikaId || process.env.NEXT_PUBLIC_YMETRIKA_ID || 0};

                window._tmr =  window._tmr || [];
                window.VK_PIXEL_ID = {vkPixelId || 0};
            </script>

            {parse(headerCounters)}
        </Head>
    );
}
