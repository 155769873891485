import { useState } from "react";

import Head from "next/head";
import Link from "next/link";

import AuthModal from "../components/authmodal";
import Layout from "../components/layout";
import ThumbnailPicture from "../components/thumbnailpicture";
import FranchiseeRequestModal from "../components/franchisee/franchiseerequestmodal";
import { urlToMedia } from "../lib/content";

function StaticPageImage({ page }) {
    if (!page.image) {
        return null;
    }

    return (
        <div className={`static-page__image static-page__image--${page.image_position}`}>
            <ThumbnailPicture thumbnail={page.thumbnail} alt={page.title} />
        </div>
    );
}

export default function StaticPage({ static_page, layoutProps }) {
    const page = static_page;
    const { user } = layoutProps;
    const userIsLogged = user && user.id;

    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [franchiseeReqModalVisible, setFranchiseeModalVisible] = useState(false);

    const showAuthModal = (event) => {
        event.preventDefault();
        setAuthModalVisible(true);
    };

    const showFranchiseeModal = (event) => {
        event.preventDefault();
        setFranchiseeModalVisible(true);
    };

    const attachments = static_page.attachments && static_page.attachments.length > 0 ? static_page.attachments : null;

    return (
        <Layout pageClass="page-staticpage" {...layoutProps}>
            <Head>
                <title>{page.title}</title>
            </Head>

            <div className="container-fluid static-page">
                <h1>
                    {page.title}

                    {page.admin_url && (
                        <>
                            {" "}
                            <a href={page.admin_url} className="static-page__edit-btn" title="Изменить">
                                <img src="/img/icons/icon-edit.svg" width="17" height="17" alt="" />
                            </a>
                        </>
                    )}
                </h1>
                {page.actual_page && (
                    <div className="text-center mt-n4 mb-4">
                        Актуальная версия:{" "}
                        <Link href={page.actual_page.url}>
                            <a>{page.actual_page.title}</a>
                        </Link>
                    </div>
                )}
                <StaticPageImage page={page} />
                <div className="static-page__content" dangerouslySetInnerHTML={{ __html: page.content }} />
                {attachments && (
                    <>
                        <h5 className="mt-5">Список прикреплённых файлов</h5>
                        {attachments.map((attachment, i) => (
                            <div className="attachment" key={i}>
                                <span className="attachment__icon">
                                    {attachment.icon ? (
                                        <a href={urlToMedia(attachment.file_url)}>
                                            <ThumbnailPicture
                                                thumbnail={attachment.icon.icon}
                                                width={32}
                                                alt=""
                                                title={attachment.file_name}
                                            />
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                <span className="attachment__link">
                                    <a href={urlToMedia(attachment.file_url)}>{attachment.file_name}</a>
                                </span>
                            </div>
                        ))}
                    </>
                )}
                {page.updated_at && (
                    <div className="mt-3">
                        <strong>Версия документа обновлена {page.updated_at}</strong>
                    </div>
                )}
                {page.changes && (
                    <>
                        <div className="mt-3">
                            <strong>Изменения:</strong>
                        </div>
                        <div className="static-page__changes" dangerouslySetInnerHTML={{ __html: page.changes }} />
                    </>
                )}
                {page.previous_version && (
                    <div>
                        Предыдущая версия:{" "}
                        <Link href={page.previous_version.url}>
                            <a>{page.previous_version.url}</a>
                        </Link>
                    </div>
                )}
                {page.page_type == "franchisee" && (
                    <div className="text-center pt-2">
                        <button className="btn btn-primary" onClick={showFranchiseeModal}>
                            Оставить заявку
                        </button>
                    </div>
                )}
                {page.show_login_btn && !userIsLogged && (
                    <div className="text-center pt-2">
                        <button className="btn btn-primary static-page__login-btn" onClick={showAuthModal}>
                            {page.login_btn_text || "Войти"}
                        </button>
                    </div>
                )}
            </div>
            {authModalVisible && <AuthModal title={page.login_modal_title} onHide={() => setAuthModalVisible(false)} />}
            {franchiseeReqModalVisible && (
                <FranchiseeRequestModal
                    title={page.settings?.request_modal_title}
                    reqTelegram={page.settings?.contact_telegram_franchisee}
                    reqPhone={page.settings?.contact_phone_franchisee}
                    onHide={() => setFranchiseeModalVisible(false)}
                />
            )}
        </Layout>
    );
}
